import { Box, Button, Container, Heading, HStack, useToast } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { catalogApi } from '../firebase/catalog';
import { useUser } from '../hooks';
import { ProductForm } from '../components';
import { Product } from '../types/product';
import { useMemo } from 'react';

const EditProduct = () => {
  const id = useParams().id;
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { idToken } = useUser();

  // const { data } = useQuery(['product', { id }], () => catalogApi.getById(id as string));
  const { data: products } = useQuery(['catalog'], catalogApi.get, {
    staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
    cacheTime: 30 * 60 * 1000, // Keep in cache for 30 minutes
    refetchOnWindowFocus: false, // Disable automatic refetch on window focus
  });

  const data = useMemo(() => products?.find((product: any) => product.id === id), [products, id]);

  const { mutate: setItemsMutation } = useMutation(catalogApi.setProducts, {
    onSuccess: () => {
      toast({
        title: 'Product deleted succesfully',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      navigate('/catalog');
      queryClient.invalidateQueries(['catalog-edit-products']);
      window.scrollTo(0, 0);
    },
  });

  const removeProduct = (productName: string) => {
    const newProducts = products
      ?.filter((product) => product.name !== productName)
      ?.map((product: any) =>
        product?.relatedProducts?.includes(productName)
          ? {
              ...product,
              relatedProducts: product?.relatedProducts.filter(
                (relatedProduct: string) => relatedProduct !== productName,
              ),
            }
          : product,
      );

    setItemsMutation({ products: newProducts as Product[], idToken });
  };

  return (
    <Container maxW="8xl" mx="auto" minH={'calc(100vh - 112px)'} pb={20}>
      <HStack mb={8} justifyContent="space-between">
        <Heading size="lg">Edit product</Heading>
        <HStack>
          <Link to="/catalog">
            <Button variant="ghost" colorScheme="orange" size="md">
              Cancel
            </Button>
          </Link>
          <Button
            colorScheme="red"
            size="md"
            onClick={() =>
              confirm('Are you sure you want to delete this product?') && removeProduct(data?.name)
            }
          >
            Delete product
          </Button>
        </HStack>
      </HStack>
      <Box bg="white" p={6} border="1px" borderColor="gray.100">
        <ProductForm data={data} id={id} />
      </Box>
    </Container>
  );
};

export default EditProduct;
