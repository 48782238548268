import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  useToast,
  Image,
  Flex,
} from '@chakra-ui/react';
import { GroupBase, OptionsOrGroups, Select } from 'chakra-react-select';
import { FileUploader } from 'react-drag-drop-files';

import { Product, ProductAvailability, ProductCategory, ProductStatus } from '../types/product';
import { storage } from '../firebase';
import { catalogApi } from '../firebase/catalog';
import { useUser } from '../hooks';
import { generateId } from '../utils/text';

import { CategorySelect } from './CategorySelect';
import { LanguagesFormTabs } from './LanguagesFormTabs';
import { Detail } from './Details';

interface ProductFormProps {
  data?: any;
  id?: string;
}

const initialFormValues = {
  name: '',
  subtitle: '',
  description: '',
  img: '',
  category: ProductCategory.Others,
  status: ProductStatus.Draft,
  availability: ProductAvailability.Available,
  relatedProducts: null,
  maxQty: null,
  details: null,
  ro: {
    name: '',
    subtitle: '',
    description: '',
    details: null,
  },
  ru: {
    name: '',
    subtitle: '',
    description: '',
    details: null,
  },
  price: undefined,
};

const FALLBACK_IMAGE = '/fallback.png';

const formatOptions = (array: any[]) => array.map((item: any) => ({ label: item, value: item }));

const formatDetails = (details: Detail[] | undefined) =>
  details?.length ? details.map((detail: Detail) => detail.value) : [];

export const ProductForm = ({ data, id }: ProductFormProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();
  const { idToken } = useUser();

  const initialData = data
    ? {
        ...data,
        status: data?.status !== ProductStatus.Draft ? ProductStatus.Public : ProductStatus.Draft,
        availability: data?.availability === 'soon' ? ProductAvailability.Soon : data?.availability,
        relatedProducts: data?.relatedProducts ? formatOptions(data?.relatedProducts) : null,
      }
    : initialFormValues;
  const initialImage = data?.img
    ? !data?.isFromFirebase
      ? `https://rental.brmg.md/${data?.img}`
      : data?.img
    : FALLBACK_IMAGE;

  const [formValues, setFormValues] = useState(initialData);
  const [productsOptions, setProductsOptions] = useState<{ value: string; label: string }[]>([]);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState(initialImage);
  const [details, setDetails] = useState<Detail[]>([]);
  const [detailsRo, setDetailsRo] = useState<Detail[]>([]);
  const [detailsRu, setDetailsRu] = useState<Detail[]>([]);

  const toastNotification = (title: string, status: 'success' | 'error') =>
    toast({
      title,
      status,
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });

  const { data: products } = useQuery(['related-products'], catalogApi.get, {
    onSuccess: (data) => {
      setProductsOptions(
        formatOptions(
          data
            .filter((item: Product) => item.name !== formValues.name)
            .map((item: Product) => item.name),
        ),
      );
    },
  });

  const { mutate: addMutation } = useMutation(catalogApi.add, {
    onSuccess: () => {
      toastNotification('Product added succesfully', 'success');
      navigate('/catalog');
      queryClient.invalidateQueries(['catalog']);
      window.scrollTo(0, 0);
    },
  });

  const { mutate: setItemsMutation } = useMutation(catalogApi.setProducts, {
    onSuccess: () => {
      queryClient.invalidateQueries(['catalog', 'product']);
      toastNotification('Product updated succesfully', 'success');
      navigate(-1);
      window.scrollTo(0, 0);
    },
  });

  const updateProducts = (product: Product) => {
    if (!products) return;

    const newProducts = products
      .map((item: Product) => {
        if (item.relatedProducts?.includes(data.name)) {
          return {
            ...item,
            relatedProducts: item.relatedProducts.map((name: string) =>
              name === data.name ? product.name : name,
            ),
          };
        }
        return item;
      })
      .map((item: Product) => (item.id === product.id ? product : item));

    setItemsMutation({ products: newProducts, idToken });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) =>
    setFormValues((prevFormValues: any) => ({
      ...prevFormValues,
      [e.target.name]: e.target.value,
    }));

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!image && !data) {
      toastNotification('An image is required', 'error');
      return;
    }

    if (!data && products?.find((product: Product) => product.name === formValues.name)) {
      toastNotification('Product name already exists', 'error');
      return;
    }

    const product: Product = {
      ...formValues,
      relatedProducts:
        formValues?.relatedProducts?.length > 0
          ? formValues.relatedProducts
              .filter((prod: any) => prod !== null)
              .map((relatedProduct: any) => relatedProduct.value)
          : null,
      updatedAt: new Date(),
      ...(image && {
        img: imageUrl,
        isFromFirebase: true,
      }),
      ...(data ? { id } : { createdAt: new Date() }),
      details: formatDetails(details),
      ro: {
        ...formValues.ro,
        details: formatDetails(detailsRo),
      },
      ru: {
        ...formValues.ru,
        details: formatDetails(detailsRu),
      },
      ...(!data && { id: generateId() }),
    };

    data ? updateProducts(product) : addMutation({ product, idToken });
  };

  const handleImageUpload = async (file: any) => {
    console.log(file);

    if (file) {
      setImage(file);
      const path = `images/${formValues.category}/${file.name}`;
      const imgRef = ref(storage, path);
      await uploadBytes(imgRef, file);
      const downloadUrl = await getDownloadURL(imgRef);
      setImageUrl(downloadUrl);
    }
  };

  useEffect(() => {
    if (
      !!formValues?.relatedProducts?.length &&
      typeof formValues?.relatedProducts[0] === 'string' &&
      productsOptions
    )
      setFormValues((prev: any) => ({
        ...prev,
        relatedProducts: formatOptions(formValues.relatedProducts),
      }));
  }, [formValues?.relatedProducts, productsOptions]);

  useEffect(() => {
    setFormValues(initialData);
    setImageUrl(initialImage);
  }, [data]);

  return (
    <form id="product-form" onSubmit={handleSubmit}>
      <Stack spacing={6}>
        <FormControl mb={8} isRequired>
          <Image src={imageUrl} alt="product" maxHeight="200px" mb={6} />
          <FormLabel>Image</FormLabel>
          {/* <input
            type="file"
            accept="image/*"
            id="file-input"
            className="file-input"
            onChange={handleImageUpload}
          />
          <label htmlFor="file-input" className="file-input-label">
            Choose file
          </label> */}
          <FileUploader
            handleChange={handleImageUpload}
            name="file"
            id="file-input"
            className="file-uploader"
            types={['PNG', 'JPG', 'JPEG', 'WEBP']}
          />
        </FormControl>

        <LanguagesFormTabs
          formValues={formValues}
          setFormValues={setFormValues}
          handleChange={handleChange}
          details={details}
          setDetails={setDetails}
          detailsRo={detailsRo}
          setDetailsRo={setDetailsRo}
          detailsRu={detailsRu}
          setDetailsRu={setDetailsRu}
        />

        <HStack spacing={6}>
          <FormControl id="category" isRequired>
            <FormLabel>Category</FormLabel>
            <CategorySelect
              width="100%"
              value={formValues?.category}
              setCategory={handleChange}
              isAllDisabled
            />
          </FormControl>
          <FormControl id="stock">
            <FormLabel>Stock</FormLabel>
            <Input
              type="number"
              name="maxQty"
              value={formValues?.maxQty as number}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl id="price">
            <FormLabel>Price (EUR)</FormLabel>
            <Input
              type="number"
              name="price"
              value={formValues?.price as number}
              onChange={handleChange}
            />
          </FormControl>
        </HStack>
        <Flex>
          <FormControl id="status" isRequired>
            <FormLabel>Status</FormLabel>
            <RadioGroup
              onChange={(value) =>
                setFormValues((prevFormValues: any) => ({
                  ...prevFormValues,
                  status: value,
                }))
              }
              value={formValues.status}
            >
              <Stack direction="row">
                <Radio value="draft">Draft</Radio>
                <Radio value="public">Public</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <FormControl id="availability" isRequired>
            <FormLabel>Availability</FormLabel>
            <RadioGroup
              onChange={(value) =>
                setFormValues((prevFormValues: any) => ({
                  ...prevFormValues,
                  availability: value,
                }))
              }
              value={formValues.availability}
            >
              <Stack direction="row">
                <Radio value="available">Available</Radio>
                <Radio value="unavailable">Unavailable</Radio>
                <Radio value="soon">Soon</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </Flex>
        <FormControl id="relatedProducts">
          <FormLabel>Related products (4 max)</FormLabel>
          <Select
            options={
              productsOptions?.length
                ? (productsOptions?.filter((product: any) =>
                    product?.value ? product : null,
                  ) as OptionsOrGroups<any, GroupBase<any>>)
                : undefined
            }
            value={formValues?.relatedProducts?.filter((product: any) => product?.value)}
            onChange={(values) =>
              setFormValues((prevFormValues: any) => ({
                ...prevFormValues,
                relatedProducts: values,
              }))
            }
            isMulti
          />
        </FormControl>

        <Button type="submit" form="product-form" colorScheme="orange" variant="solid">
          Save
        </Button>
      </Stack>
    </form>
  );
};
